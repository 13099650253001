const cookie = {
  setCookie: function (cookieName, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var cookieValue =
      escape(value) +
      (exdays == null ? "" : "; expires=" + exdate.toGMTString()) +
      "; path=/";
    document.cookie = cookieName + "=" + cookieValue;
  },
  deleteCookie: function (cookieName) {
    var expireDate = new Date();
    expireDate.setDate(expireDate.getDate() - 1);
    document.cookie =
      cookieName + "= " + "; expires=" + expireDate.toGMTString() + "; path=/;";
  },

  getCookie: function (cookieName) {
    cookieName = cookieName + "=";
    var cookieData = document.cookie;
    var start = cookieData.indexOf(cookieName);
    var cookieValue = "";
    if (start != -1) {
      start += cookieName.length;
      var end = cookieData.indexOf(";", start);
      if (end == -1) end = cookieData.length;
      cookieValue = cookieData.substring(start, end);
    }
    return unescape(cookieValue);
  },
};

export default cookie;
